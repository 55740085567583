<template>
  <el-dialog 
    title="编辑"
    :visible="visible"
    :close-on-click-modal="true"
    :before-close="handleClose"
    width="650px"
  >
    <gl-wrap right="30px" v-loading="loading">
      <el-form :model="formData" ref="form" label-width="100px" width="500px" size="mini" v-loading="loading" >
        <el-form-item prop="categoryName" label="分类名称" verify>
          <el-input v-model="formData.categoryName"></el-input>
        </el-form-item>
        <el-form-item prop="pictureUrl" label="分类图片" verify :watch="formData.pictureUrl"> 
          <gl-upload-images 
            :limit="1" 
            :type="2"
            v-model="formData.pictureUrl" 
            ref="uploadImages" 
            :oldList="oldList" 
            :autoUpload="true">
          </gl-upload-images>
        </el-form-item>
      </el-form> 
    </gl-wrap>
    <gl-flex>
      <el-button type="primary" @click="onSubmit" >确认</el-button>
      <el-button @click="handleClose" >关闭</el-button>
    </gl-flex>
  </el-dialog>
</template>

<script>
import goodsApi from '@/api/backendall/goods'
export default {
  name: 'updateCategory',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    itemId: {
      type: String,
      default: '',
    },
  },
  data(){
    return{
      formData: {},
      oldList: [],
      loading: false,
    }
  },
  watch: {
    visible: {
      handler: function(newVal){
        newVal && this.getCategory()
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    onSubmit(){
      this.$refs.form.validate(async valid => {
        if(!valid) return false
        this.loading = true
        let params = {
          categoryId: this.formData.categoryId,
          categoryName: this.formData.categoryName,
          pictureUrl: this.formData.pictureUrl,
          parentId: this.formData.parentId,
          categoryLevel: this.formData.categoryLevel,
        }
        const res = await goodsApi.updateCategory(params)
        this.loading = false
        this.$message.success('操作成功')
        this.handleClose()
        this.$emit('reload')
      })
    },
    handleClose(){
      this.$emit('update:visible', false)

      // setTimeout(() => {
      //   this.$refs.form.resetFields()
      //   this.formData = {}
      // }, 10)
    },
    async getCategory(){
      let params = {
        categoryId: this.itemId
      }
      const res = await goodsApi.getCategory(params)
      this.formData = res.data
      this.oldList = [this.formData.pictureUrl]
    },
  },
}
</script>

<style>

</style>
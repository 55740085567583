<template>
  <el-dialog 
    title="新增"
    :visible="visible"
    :close-on-click-modal="true"
    :before-close="handleClose"
    width="650px"
  >
    <gl-wrap right="30px">
      <el-form :model="formData" ref="form" label-width="100px" width="500px" size="mini" v-loading="loading" >
        <el-form-item prop="categoryName" label="分类名称" verify>
          <el-input v-model="formData.categoryName"></el-input>
        </el-form-item>
        <el-form-item prop="pictureUrl" label="分类图片" verify :watch="formData.pictureUrl" v-if="visible"> 
          <gl-upload-images 
            :limit="1" 
            :type="2"
            v-model="formData.pictureUrl" 
            ref="uploadImages" 
            :oldList="oldList" 
            :autoUpload="true">
          </gl-upload-images>
        </el-form-item>
      </el-form> 
    </gl-wrap>
    <gl-flex>
      <el-button type="primary" @click="onSubmit" >确认</el-button>
      <el-button @click="handleClose" >关闭</el-button>
    </gl-flex>
  </el-dialog>
</template>

<script>
import goodsApi from '@/api/backendall/goods'
export default {
  name: 'addCategory',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    itemId: {
      type: String,
      default: ''
    }
  },
  data(){
    return{
      formData: {
        categoryName: '',
        pictureUrl: '',
        parentId: '0',
        categoryLevel: 2
      },
      oldList: [],
      loading: false,
    }
  },
  methods: {
    onSubmit(){
      this.$refs.form.validate(async valid => {
        if(!valid) return false
        // const imgs = await this.$refs.uploadImages.upload()
        this.loading = true
        let params = {
          categoryName: this.formData.categoryName,
          pictureUrl: this.formData.pictureUrl,
          parentId: this.itemId || '0',
          categoryLevel: 2,
        }
        const res = await goodsApi.addCategory(params)
        this.loading = false
        this.$message.success('操作成功')
        this.handleClose()
        this.$emit('reload')
      })
    },
    handleClose(){
      this.$emit('update:visible', false)
      setTimeout(() => {
        this.$refs.form.resetFields()
        this.formData = {}
      }, 100)
    },
    async getCategory(){
      let params = {
        categoryId: this.itemId
      }
      const res = await goodsApi.getCategory(params)
      this.formData = res.data
      this.oldList = [this.formData.pictureUrl]
    },
  },
}
</script>

<style>

</style>
<template>
  <div>
    <gl-title :title="$route.meta.title"></gl-title>
    <div style="margin: 18px;">
      <el-button v-check="'103011'" type="primary" size="mini" @click="onClickAction('addVisible')"> 新增 </el-button>
    </div>
    <gl-card v-loading="mLoading">
      <el-table 
        :data="mTableData" 
        style="width: 100%" 
        row-key="categoryId"
        :tree-props="{children: 'subGategoryList', hasChildren: 'hasChildren'}">
        <el-table-column type="index" :index="mIndexMethod" label="序号" align="center" width="44"></el-table-column>
        <el-table-column prop="categoryName" label="分类名称"></el-table-column>
        <el-table-column prop="pictureUrl" label="分类图片">
          <template slot-scope="scope">
            <el-image
              style="width: 60px; height: 60px"
              :src="scope.row.pictureUrl"
              fit="cover"
              :preview-src-list="[scope.row.pictureUrl]"
            />
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="{row}">
            <el-button v-if="row.subGategoryList != null" v-check="'103011'" type="text" @click="onClickAction('addVisible', row)"> 添加子类 </el-button>
            <el-button type="text" v-check="'103012'" @click="onClickAction('updateVisible', row)"> 编辑 </el-button>
            <el-button type="text" v-check="'103013'" @click="mDelete(row.categoryId)"> 删除 </el-button>
          </template>
        </el-table-column>
      </el-table>
      <gl-pagination
        :total="mTotal"
        :current-page="mPageNum"
        @currentChange="mHandleCurrentChange"
        @sizeChange="mHandleSizeChange">
      </gl-pagination>
    </gl-card>
    <addCategory :visible.sync="addVisible" :itemId="itemId" @reload="mReload"/>
    <updateCategory v-if="updateVisible" :visible.sync="updateVisible" :itemId="itemId" @reload="mReload"/>
  </div>
</template>

<script>
import updateCategory from './updateCategory.vue'
import addCategory from './addCategory.vue'
import ListMixin from '@/mixins/list.mixin.js'
import goodsApi from '@/api/backendall/goods'

export default {
  components: {updateCategory,addCategory},
  mixins: [ListMixin],
  data(){
    return {
      updateVisible: false,
      addVisible: false,
      itemId: '',
    }
  },
  mounted(){
    this.mGetListFun = goodsApi.categoryList
    this.mDeleteFun = goodsApi.deleteCategory
    this.mGetList()
  },
  methods: {
    onClickAction(name, item){
      this.itemId = item?.categoryId || '' 
      this[name] = true
    }
  },
}
</script>

<style>

</style>